@keyframes bubble {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    25% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  
  .animate-bubble {
    animation-name: bubble;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .bubble-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .bubble {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #0099ff;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: bubble-scatter 1s ease-out;
  }
  
  .donation-amount {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }
  
  @keyframes bubble-scatter {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
  
  .bubble-small {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f18b0f;
    animation: move-bubble 2s ease-out forwards;
  }
  
  @keyframes move-bubble {
    0% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    50% {
      opacity: 0;
      transform: translate(200%, -50%);
    }
    100% {
      opacity: 0;
      transform: translate(200%, -50%);
    }
  }
  
  